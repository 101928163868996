import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class DrawerData {
  public header = '';
  public content: TemplateRef<{}> = null;
  public footer: TemplateRef<{}> = null;

  public constructor(header: string, content: TemplateRef<{}>, footer: TemplateRef<{}>) {
    this.header = header;
    this.content = content;
    this.footer = footer;
  }
}

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private readonly data = new BehaviorSubject<DrawerData>(null);
  // tslint:disable-next-line: member-ordering
  public data$ = this.data.asObservable();

  public setData(data: DrawerData): void {
    this.data.next(data);
  }
}
