<footer>
  <div row-footer>
    <img alt="logo-sx" src="assets/images/SX Branco.svg" height="28">
  </div>
  <div row-footer>
    <div box-footer>
      <p titulo-empresa>Uma empresa</p>
      <img src="assets/images/Santander.svg" height="26" alt="logo-santander">
      <!-- <p titulo-sociais>Redes sociais</p>
      <img img-sociais src="assets/icon/icons-social-ic-social-instagram.svg" alt="instagram">
      <img img-sociais src="assets/icon/icons-social-ic-social-facebook.svg" alt=facebook>
      <img img-sociais src="assets/icon/icons-social-ic-social-linkedin.svg" alt=linkedin> -->
    </div>
    <div box-footer m-column>
      <div grupo-icone-footer>
        <img src="assets/icon/icons-channels-ic-career-agile-white.svg" height="20">
        <p titulo-coluna-footer>Nossas soluções</p>
      </div>
      <div row-footer coluna-solucao>
        <a footer-link-solucoes href="/#/fornecedor" (click)="onLinkClicked('footer-para-fornecedores')">Para Fornecedores</a>
        <a footer-link-solucoes href="/#/comprador" (click)="onLinkClicked('footer-para-compradores')">Para Compradores</a>
      </div>
    </div>
    <div box-footer m-column>
      <div grupo-icone-footer>
        <img src="assets/icon/icons-channels-ic-call-center.svg" height="20">
        <p titulo-coluna-footer>Central de Atendimento</p>
      </div>
      <div row-footer coluna-duvidas>
        <p footer-duvidas>Para dúvidas e transações</p>
        <p footer-horario>De segunda à sexta-feira das 9h às 18h (dias úteis). </p>
        <p footer-telefone>0800 723 5073</p>

      </div>
    </div>
  </div>
</footer>
