import { TaggingEventEnum } from './../../enumerators/tagging-event-enum';
import { SxiTaggingService } from './../../services/tagging/sxi-tagging.service';
import { Component, ElementRef, HostBinding, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DrawerData, DrawerService } from '../../services/drawer/drawer.service';


@Component({
  selector: 'sx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  openProductCard: boolean;
  openProductCardMobile: boolean;

  @HostBinding('attr.area') area = 'default';

  @ViewChild('drawerContent') public drawerContent: TemplateRef<{}>;
  buttonType: Subject<string> = new BehaviorSubject('');
  private category: string;
  constructor(
    public router: Router,
    private readonly drawerService: DrawerService,
    private mSxiTaggingService: SxiTaggingService
  ) {

  }

  ngOnInit(): void {
    this.openProductCard = false;
    this.openProductCardMobile = false;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event) => {
        let _area = 'default';

        switch (true) {
          case (event as NavigationEnd).url.indexOf('comprador') != -1:
            _area = 'comprador';
            this.category = TaggingEventEnum.CATEGORY_COMPRADOR;
            break;

          case (event as NavigationEnd).url.indexOf('fornecedor') != -1:
            _area = 'fornecedor';
            this.category = TaggingEventEnum.CATEGORY_FORNECEDOR;
            break;

          default:
            _area = 'default';
            this.category = TaggingEventEnum.CATEGORY_LANDINGPAGE;

        }

        this.area = _area;
        this.buttonType.next(this.area == 'comprador' || this.area == 'fornecedor' ? 'screen' : 'secondary');
      });
  }

  onEntrarClick() {
    this.mSxiTaggingService.eventButton('entrar', this.category);
    /*
      Se estiver rodando local, precisa ajustar o valor do trecho /app-host/ pois está sendo substituido no replace tokens
     */
    window.open("/app-host/sxi-frontend-op", "_self");
  }

  onPrimeiroAcesso() {
    /*
      Se estiver rodando local, precisa ajustar o valor do trecho /app-host/ pois está sendo substituido no replace tokens
     */
    window.open("/app-host/sxi-frontend-op/password-recover", "_self");
  }

  public onLinkClicked(labelName: string): void {
    this.drawerService.setData(new DrawerData(labelName, this.drawerContent, null));
    this.mSxiTaggingService.eventLink(labelName, this.category);
  }
}
