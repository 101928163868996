<div (click)="close()" class="imaginary-box"></div>

<div [style]="styles">
  <dss-product-card>
    <dss-product-card-title class="Dialog-title">Primeiro acesso</dss-product-card-title>
    <dss-product-card-content>
      <p class="Message">Caso seja Fornecedor cadastrado, realize seu primeiro acesso usando a página "Recuperação de
        senha".</p>
    </dss-product-card-content>
    <dss-product-card-actions>
      <button dssButton (click)="close()">
        Entendi
      </button>
    </dss-product-card-actions>
  </dss-product-card>
</div>
