<div snackBar-container>
    <div snackBar-type>
        <div circle-error *ngIf="data.type === 'error'">
            <img circle-icon src="assets/icon/check_circle_white_24dp.svg" alt="icone-botao">
        </div>
        <div circle-success *ngIf="data.type === 'success'">
            <img circle-icon src="assets/icon/check_circle_white_24dp.svg" alt="icone-botao">
        </div>
        <div circle-atention *ngIf="data.type === 'atention'">3</div>
    </div>
    <div snackBar-message> {{data.message}}</div>
    <div snackBar-button>
        <a (click)="snackBarRef.dismiss()">
            <img btn-icon src="assets/icon/close_black_24dp.svg" alt="icone-botao">
        </a>
    </div>
</div>