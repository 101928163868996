import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-imaginary-box',
  templateUrl: './imaginary-box.component.html',
  styleUrls: ['./imaginary-box.component.scss']
})
export class ImaginaryBoxComponent implements OnInit {

  @Input() styles: string;
  @Input() openProductCard: boolean;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    console.log(this.styles);
    console.log(this.openProductCard);
  }

  public close() {
    console.log('close');
    this.closeEvent.emit(true);
  }

}
