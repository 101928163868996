import { TaggingGtmConnector } from '@afe/tagging/gtm-connector';
import { Injectable } from '@angular/core';
import { TaggingEventEnum } from '../../enumerators/tagging-event-enum';

@Injectable({
  providedIn: 'root'
})
export class SxiTaggingService {

  private readonly PAGEVIEW_PATH = '/sx-integra';

  private readonly categoryGtm: string = 'sx-integra:';

  constructor(
    private readonly taggingGtmConnector: TaggingGtmConnector,
  ) { }

  public eventButton(button: string, category: string, action: string = undefined): void {
    this.taggingGtmConnector.tag({
      event: TaggingEventEnum.INTERACTION,
      action: action || 'clicou',
      label: `botao:${this.handlerCharacters(button)}`,
      nonInteraction: false,
      category: `${this.categoryGtm}${category}`,
    });
  }

  public eventLink(link: string, category: string): void {
    this.taggingGtmConnector.tag({
      event: TaggingEventEnum.INTERACTION,
      action: 'clicou',
      label: `link:${this.handlerCharacters(link)}`,
      nonInteraction: false,
      category: `${this.categoryGtm}${category}`,
    });
  }

  public pageView(page: string, title: string): void {
    this.taggingGtmConnector.tag({
      event: TaggingEventEnum.PAGEVIEW,
      page: `${this.PAGEVIEW_PATH}/${page}`,
      title: `${this.handlerCharacters(title)}`,
    });
  }

  private handlerCharacters(value: string): string {
    return value
      .replace(/[ ]/g, '-')
      .replace(/[áÁãÃâÂ]/g, 'a')
      .replace(/[éÉêÊ]/g, 'e')
      .replace(/[íÍ]/g, 'i')
      .replace(/[óÓõÕôÔ]/g, 'o')
      .replace(/[úÚ]/g, 'u')
      .replace(/[çÇ]/g, 'c').trim().toLowerCase();
  }
}
