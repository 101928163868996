<div nav-bar [attr.area]="area" id="topo">
  <div nav-bar-mobile>
    <button type="button" mat-button-menu (click)="drawer.toggle()">
      <img *ngIf="area === 'default'" alt="menu" src="assets/icon/icons-system-ic-system-menu.svg">
      <img *ngIf="area != 'default'" alt="menu" src="assets/icon/icons-system-ic-system-menu-white.svg">
    </button>
  </div>
  <div area-logo>
    <a href="/">
      <img logo *ngIf="area == 'default'" src="assets/images/SX Colorido.svg" width="141" height="30" />
      <img logo *ngIf="area != 'default'" src="assets/images/SX Branco.svg" width="141" height="30" />
    </a>
    <div area *ngIf="area != 'default'">
      <small>Confirming</small>
      <p>{{ area }}</p>
    </div>
  </div>
  <nav>
    <a [href]="'#solucoes'">
      <span *ngIf="area == 'default'" (click)="onLinkClicked('solucoes')">Soluções</span>
      <span *ngIf="area != 'default'" (click)="onLinkClicked('como-funciona')">Como funciona</span>
    </a>
    <a [href]="area == 'default' ? '#vantagens' : '/#/' + area + '/faq'">
      <span *ngIf="area == 'default'" (click)="onLinkClicked('vantagens')">Vantagens</span>
      <span *ngIf="area != 'default'" (click)="onLinkClicked('faq')">FAQ</span>
    </a>
    <a href="#">
      <span (click)=onPrimeiroAcesso()>Primeiro acesso</span>
      <div (mouseover)="openProductCard=true" ng-init="count=0" class="Icon1">
        <span *ngIf="area == 'default'" class="Icon" dssIcon name="circle-help-filled"></span>
        <span *ngIf="area != 'default'" class="Icon" dssIcon name="circle-help-filled"></span>
      </div>
    </a>
    <a>
      <span>
        <button mat-flat-button class="login" btn-entrar-mb [attr.color]="buttonType | async" (click)=onEntrarClick()>
          <span>Acessar</span>
        </button>
      </span>
    </a>
  </nav>
</div>
<app-imaginary-box (closeEvent)="openProductCard=false" *ngIf="openProductCard"
  [styles]="'position: fixed; transform: translate(283%, 28%); z-index: 999999'"></app-imaginary-box>
<mat-drawer #drawer container-nav-mb mode="over">
  <div group-menu-mb>
    <p descricao-menu-mb>Menu</p>
    <p (click)="drawer.toggle()" fechar-menu-mb>X</p>
  </div>
  <div descricao-menu-item-mb (click)=onEntrarClick()>
    <img alt="entrar" src="assets/icon/icons-channels-ic-account-user.svg" height="24">
    <p descricao-menu-mb>Acessar</p>
  </div>
  <div descricao-menu-item-mb>
    <img alt="entrar" src="assets/icon/icons-santander-ic-std-academy.svg" height="24">
    <a [href]="area == 'default' ? '#solucoes' : '/#/' + area + '#solucoes'" descricao-menu-mb
      (click)="drawer.toggle()">
      <span *ngIf="area == 'default'" (click)="onLinkClicked('solucoes')">Soluções</span>
      <span *ngIf="area != 'default'" (click)="onLinkClicked('como-funciona')">Como funciona</span>
    </a>
  </div>
  <div descricao-menu-item-mb>
    <img alt="entrar" src="assets/icon/icons-channels-ic-experience.svg" height="24">
    <a href="#vantagens" descricao-menu-mb (click)="onLinkClicked('vantagens'); drawer.toggle()">Vantagens</a>
  </div>
  <div *ngIf="area == 'default'" descricao-menu-item-mb>
    <img *ngIf="area == 'default'" alt="entrar" src="assets/icon/icons-channels-ic-career-agilee.svg" height="24">
    <img *ngIf="area != 'default'" alt="entrar" src="assets/icon/icons-channels-ic-career-agilee.svg" height="24">
    <a href="#" descricao-menu-mb (click)="drawer.toggle()">
        <span (click)=onPrimeiroAcesso()>Primeiro acesso</span>
    </a>
    <div (click)="openProductCardMobile=true" ng-init="count=0" class="Icon1">
      <span *ngIf="area == 'default'" dssIcon name="circle-help-filled"></span>
      <span *ngIf="area != 'default'" dssIcon name="circle-help-filled"></span>
    </div>
  </div>
  <div *ngIf="area != 'default'" descricao-menu-item-mb>
    <img *ngIf="area == 'default'" alt="entrar" src="assets/icon/icons-channels-ic-career-agilee.svg" height="24">
    <img *ngIf="area != 'default'" alt="entrar" src="assets/icon/icons-channels-ic-career-agilee.svg" height="24">
    <a href="#" descricao-menu-mb (click)="drawer.toggle()">
        <span (click)=onPrimeiroAcesso()>Primeiro acesso</span>
    </a>
    <div (click)="openProductCardMobile=true" ng-init="count=0" class="Icon1">
      <span dssIcon name="circle-help-filled"></span>
    </div>
  </div>
</mat-drawer>
<app-imaginary-box (closeEvent)="openProductCardMobile=false" *ngIf="openProductCardMobile"
  [styles]="'position: fixed; transform: translate(16px, 320px); z-index: 999999'"></app-imaginary-box>
