import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { DssButtonModule } from '@dss/components/button';
import { DssDialogModule } from '@dss/components/dialog';
import { DssProductCardModule } from '@dss/components/product-card';
import { DssIconModule } from '@dss/components/icon';
import { DssAlertBannerModule } from '@dss/components/alert-banner';
import { ImaginaryBoxComponent } from '../imaginary-box/imaginary-box.component';



@NgModule({
  declarations: [
    HeaderComponent,
    ImaginaryBoxComponent,
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatButtonModule,
    DssDialogModule,
    DssButtonModule,
    DssProductCardModule,
    DssIconModule,
    DssAlertBannerModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
