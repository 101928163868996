export enum TaggingEventEnum {
  PAGEVIEW = 'pageviewGA',
  INTERACTION = 'eventGA',
  PATH_LANDINGPAGE = 'landing-page',
  PATH_COMPRADOR = 'landing-page/comprador',
  PATH_FORNECEDOR = 'landing-page/fornecedor',
  CATEGORY_LANDINGPAGE = 'landing-page',
  CATEGORY_FORNECEDOR = 'lp-fornecedores',
  CATEGORY_COMPRADOR = 'lp-compradores'
}
