import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule)
  },
  {
    path: 'comprador',
    loadChildren: () => import('./pages/home-comprador/home-comprador.module').then(module => module.HomeCompradorModule),
  },
    {
      path: 'comprador/faq',
      loadChildren: () => import('./pages/faq-comprador/faq-comprador.module').then(module => module.FaqCompradorModule)
    },
  {
    path: 'fornecedor',
    loadChildren: () => import('./pages/home-fornecedor/home-fornecedor.module').then(module => module.HomeFornecedorModule)
  },
  {
    path: 'fornecedor/faq',
    loadChildren: () => import('./pages/faq-fornecedor/faq-fornecedor.module').then(module => module.FaqFornecedorModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
