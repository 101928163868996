import { TaggingEventEnum } from './../../enumerators/tagging-event-enum';
import { SxiTaggingService } from './../../services/tagging/sxi-tagging.service';
import { DrawerService, DrawerData } from './../../services/drawer/drawer.service';
import { filter } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, TemplateRef, HostBinding } from '@angular/core';

@Component({
  selector: 'sx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @ViewChild('drawerContent') public drawerContent: TemplateRef<{}>;
  private category: string;

  constructor(
    public router: Router,
    private readonly drawerService: DrawerService,
    private mSxiTaggingService: SxiTaggingService
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((event) => {
        switch(true) {
          case (event as NavigationEnd).url.indexOf('comprador') != -1:
            this.category = TaggingEventEnum.CATEGORY_COMPRADOR;
            break;

          case (event as NavigationEnd).url.indexOf('fornecedor') != -1:
            this.category = TaggingEventEnum.CATEGORY_FORNECEDOR;
            break;

          default:
            this.category = TaggingEventEnum.CATEGORY_LANDINGPAGE;

        }
      });
    }
  public onLinkClicked(label: string): void {

    this.drawerService.setData(new DrawerData(label, this.drawerContent, null));
    this.mSxiTaggingService.eventLink(label, this.category);
  }
}
