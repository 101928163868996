import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { TaggingModule } from '@afe/tagging';
import { TaggingGtmConnector } from '@afe/tagging/gtm-connector';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { FooterModule } from "./shared/components/footer/footer.module";
import { MatSidenavModule } from "@angular/material/sidenav";
import { HeaderModule } from "./shared/components/header/header.module";
import { NotifierModule } from "./shared/components/notifier/notifier.module";
import { taggingConfig } from './config/tagging.config';
import { SxiTaggingService } from "./shared/services/tagging/sxi-tagging.service";
import { DssIconModule } from "@dss/components/icon";
import { DssProductCardModule } from '@dss/components/product-card';
import { DssButtonModule } from '@dss/components/button';
import { DssDialogModule } from '@dss/components/dialog';
import { DssFormFieldModule } from '@dss/components/form-field';
import { DssTooltipModule } from '@dss/components/tooltip';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HeaderModule,
    FooterModule,
    MatSidenavModule,
    NotifierModule,
    DssIconModule,
    DssDialogModule,
    DssButtonModule,
    DssTooltipModule,
    DssFormFieldModule,
    DssProductCardModule,
    TaggingModule.forRoot(taggingConfig),
  ],
  providers: [
    SxiTaggingService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerGtm,
      deps: [TaggingGtmConnector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

// tslint:disable-next-line: only-arrow-functions
export function appInitializerGtm(taggingGtmConnector: TaggingGtmConnector): Function {
  return () => {
    taggingGtmConnector.sendCustomData({
      page: {
        domain: document.location.hostname,
        homolog: false,
      },
    });
  };
}
